import { useState } from "react";
import { FaLocationDot, FaPhone, FaEnvelope, FaInstagram } from "react-icons/fa6";
import { FaFacebookF, FaTiktok } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import "./Contact.css";

const Contact = () => {
    const [formData, setFormData] = useState({
        name: "",
        subject: "",
        message: "",
    });

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        try {
            const emailBody = `
Hi,

${formData.message}
`.trim();

            const gmailLink = `https://mail.google.com/mail/u/0/?view=cm&fs=1&tf=1&to=info@commissh.com&su=${encodeURIComponent(
                formData.subject
            )}&body=${encodeURIComponent(emailBody)}`;

            const mailtoLink = `mailto:info@commissh.com?subject=${encodeURIComponent(
                formData.subject
            )}&body=${encodeURIComponent(emailBody)}`;

            const emailContainer = document.createElement("div");
            emailContainer.style.cssText = `
            position: fixed;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background: white;
            padding: 20px;
            border-radius: 8px;
            box-shadow: 0 2px 10px rgba(0,0,0,0.1);
            z-index: 1000;
            text-align: center;
        `;

            // Store overlay reference to use in button clicks
            const overlay = document.createElement("div");

            // Function to close both overlay and container
            const closeAll = () => {
                overlay.remove();
                emailContainer.remove();
            };

            emailContainer.innerHTML = `
            <h3 style="margin-bottom: 15px;">Choose your email client:</h3>
            <button onclick="window.open('${gmailLink}', '_blank'); this.closest('div').remove(); document.querySelector('[data-overlay]').remove();" style="
              background: #4285f4;
              color: white;
              border: none;
              padding: 10px 20px;
              margin: 5px;
              border-radius: 4px;
              cursor: pointer;
            ">Open in Gmail</button>
            <button onclick="window.location.href='${mailtoLink}'; this.closest('div').remove(); document.querySelector('[data-overlay]').remove();" style="
              background: #333;
              color: white;
              border: none;
              padding: 10px 20px;
              margin: 5px;
              border-radius: 4px;
              cursor: pointer;
            ">Open in Default Mail App</button>
            <button onclick="this.closest('div').remove(); document.querySelector('[data-overlay]').remove();" style="
              background: #666;
              color: white;
              border: none;
              padding: 10px 20px;
              margin: 5px;
              border-radius: 4px;
              cursor: pointer;
            ">Cancel</button>
        `;

            overlay.style.cssText = `
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: rgba(0,0,0,0.5);
            z-index: 999;
        `;

            // Add a data attribute to easily select the overlay
            overlay.setAttribute("data-overlay", "");

            overlay.onclick = closeAll;

            document.body.appendChild(overlay);
            document.body.appendChild(emailContainer);

            // Clear form
            setFormData({
                name: "",
                email: "",
                subject: "",
                message: "",
            });
        } catch (error) {
            console.error("Error opening mail client:", error);
            alert("There was an error opening your mail client. Please try again.");
        }
    };

    return (
        <div className="contact-container">
            <h1 className="contact-title">Contact Us</h1>

            <div className="cta-container">
                Ready to partner with us? Contact us today to learn more about our marketing packages and how we can
                work together to make your event a success.
            </div>

            <div className="contact-content">
                <div className="contact-form-container">
                    <h2>Get in Touch</h2>
                    <form onSubmit={handleSubmit} className="contact-form">
                        <div className="form-group">
                            <label htmlFor="name" className="label-form">
                                Name
                            </label>
                            <input
                                type="text"
                                id="name"
                                name="name"
                                value={formData.name}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="subject" className="label-form">
                                Subject
                            </label>
                            <input
                                type="text"
                                id="subject"
                                name="subject"
                                value={formData.subject}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="message" className="label-form">
                                Message
                            </label>
                            <textarea
                                id="message"
                                name="message"
                                value={formData.message}
                                onChange={handleChange}
                                rows="4"
                                required
                            ></textarea>
                        </div>
                        <button type="submit" className="submit-button">
                            Send Message
                        </button>
                    </form>
                </div>

                <div className="contact-info-main">
                    <div className="contact-info-container">
                        <h2>Contact Information</h2>
                        <div className="contact-info">
                            <p>
                                <FaLocationDot /> 251 Valencia Ave P.O. BOX# 140997, <br />
                                Coral Gables, FL 33114
                            </p>
                            <p>
                                <FaPhone /> 305.767.6236
                            </p>
                            <p>
                                <FaEnvelope /> info@commissh.com
                            </p>
                        </div>

                        <div className="social-media">
                            <h3>Follow Us</h3>
                            <div className="social-icons">
                                <a
                                    href="https://www.facebook.com/profile.php?id=100063640896468"
                                    className="icon-button facebook"
                                    aria-label="Facebook"
                                >
                                    <FaFacebookF />
                                </a>
                                <a
                                    href="https://x.com/Commissh1"
                                    className="icon-button x-twitter"
                                    aria-label="X (formerly Twitter)"
                                >
                                    <FaXTwitter />
                                </a>
                                <a
                                    href="https://www.instagram.com/commissh/"
                                    className="icon-button instagram"
                                    aria-label="Instagram"
                                >
                                    <FaInstagram />
                                </a>
                                <a
                                    href="https://www.tiktok.com/@commissh"
                                    className="icon-button tiktok"
                                    aria-label="TikTok"
                                >
                                    <FaTiktok />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Contact;
