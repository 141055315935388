import { useState, useEffect } from "react";
import "./Navbar.css";

const Navbar = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isScrolled, setIsScrolled] = useState(false);
    const [userAgent, setUserAgent] = useState("");

    const handleDownload = () => {
        // App store URLs (replace these with your actual app URLs)
        const IOS_APP_URL = "https://apps.apple.com/ca/app/commissh/id1553224421";
        const ANDROID_APP_URL = "https://play.google.com/store/apps/details?id=com.commisshcustomer";

        // Check if device is iOS
        const isIOS = /iphone|ipad|ipod|macintosh|mac os/.test(userAgent);
        // Check if device is Android
        const isAndroid = /android/.test(userAgent);

        if (isIOS) {
            window.open(IOS_APP_URL, "_blank");
        } else if (isAndroid) {
            window.open(ANDROID_APP_URL, "_blank");
        } else {
            // For desktop or unsupported devices, you might want to show both options
            // You could either open a modal here or redirect to a landing page
            alert(
                "Please visit us on your mobile device or choose your platform:\n\niOS: " +
                    IOS_APP_URL +
                    "\nAndroid: " +
                    ANDROID_APP_URL
            );
        }
    };

    useEffect(() => {
        setUserAgent(navigator.userAgent.toLowerCase());
        const handleScroll = () => {
            setIsScrolled(window.scrollY > 50);
        };

        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    return (
        <nav className={`navbar ${isScrolled ? "scrolled" : ""}`}>
            <div className="navbar-container">
                <a href="/" className="navbar-logo">
                    <img src={require("../../../Assets/Images/commissh_white_logo.png")} className="navbar-logo" />
                </a>
                <div className={`navbar-menu ${isMenuOpen ? "active" : ""}`}>
                    <a href="/" className="navbar-item">
                        Home
                    </a>
                    {/* <a href="/ticket-giveaway" className="navbar-item">
                        Ticket Giveaway
                    </a> */}
                    <a href="/about-us" className="navbar-item">
                        About Us
                    </a>
                    <a href="/contact" className="navbar-item">
                        Contact
                    </a>
                    {/* <a href="/careers" className="navbar-item">
                        Careers
                    </a> */}
                    {/* <button className="navbar-button mobile" onClick={handleDownload}>
                        Download the App
                    </button> */}
                </div>
                <div className="navbar-button-placeholder"></div>
                {/* <button className="navbar-button desktop" onClick={handleDownload}>
                    Download the App
                </button> */}
                <div className="navbar-burger" onClick={toggleMenu}>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </div>
        </nav>
    );
};

export default Navbar;
