import "./AboutUs.css";
import ourMission from "../../../Assets/Images/our-mission.jpg";
import ourStory from "../../../Assets/Images/our-story.jpg";
import streetMarketing from "../../../Assets/Images/street_marketing.jpg";
import socialMediaMarketing from "../../../Assets/Images/social_media_marketing.png";
import influencerMarketing from "../../../Assets/Images/influencer_marketing.jpg";

const AboutUs = () => {
    return (
        <div className="about-us">
            <div className="header-image"></div>
            <h1>About Us</h1>

            <p className="intro-text">
                Commissh is a dynamic event marketing company with roots that trace back to the mid-90s when we
                organized the Colombian Independence Festival, South Florida’s largest Latin American festival. What
                began as a passion for celebrating culture and bringing people together has grown into a full-scale
                enterprise focused on event marketing, ticket giveaways, and ticket sales. Our journey took a
                significant step forward in 2018 when we officially launched Commissh with our first major event—a
                thrilling Croatia vs. Peru soccer match at Hard Rock Stadium. Since then, we’ve been expanding our
                reach, connecting communities, and delivering unforgettable experiences. At Commissh, events are more
                than just gatherings; they’re cultural celebrations that build community and create lasting memories.
                We’re proud to continue this tradition as we grow our company into a leader in event marketing, ticket
                promotions, and sales.
            </p>

            <div className="grid-container">
                <div className="grid-row">
                    <div className="grid-column">
                        <img src={ourMission} alt="Our Mission" className="grid-image" />
                    </div>
                    <div className="grid-column">
                        <h2>Our Mission</h2>
                        <p>
                            At Commissh, our mission is to revolutionize the event industry by providing innovative
                            solutions that connect event organizers with their audience. We strive to create a platform
                            where creativity flourishes, communities thrive, and memories are made. Through cutting-edge
                            technology and a user-centric approach, we aim to make every event an extraordinary
                            experience.
                        </p>
                    </div>
                </div>

                <div className="grid-row">
                    <div className="grid-column">
                        <h2>Our Story</h2>
                        <p>
                            Commissh was born out of a shared frustration with the complexities of event management and
                            attendance. Our founders, a group of tech enthusiasts and event lovers, came together with a
                            vision to simplify and enhance the event experience. From humble beginnings in a small
                            garage to becoming a leading name in the event tech industry, our journey has been driven by
                            passion, innovation, and the unwavering support of our community.
                        </p>
                    </div>
                    <div className="grid-column">
                        <img src={ourStory} alt="Our Story" className="grid-image" />
                    </div>
                </div>
            </div>

            {/* 
            Removed as per Mail "Website Development Information"
            <section className="leadership-team">
                <h2 className="section-title">Our Leadership Team</h2>
                <div className="team-grid">
                    <div className="team-member">
                        <img src={team01} alt="Rusty Ranney" />
                        <div className="team-member-info">
                            <h3>Rusty Ranney</h3>
                            <p>Chief Executive Officer</p>
                        </div>
                        <div className="team-member-hover">
                            <p>Visionary leader driving innovation</p>
                        </div>
                    </div>
                    <div className="team-member">
                        <img src={team02} alt="Tom Marks" />
                        <div className="team-member-info">
                            <h3>Tom Marks</h3>
                            <p>Chief Financial Officer</p>
                        </div>
                        <div className="team-member-hover">
                            <p>Strategic financial mastermind</p>
                        </div>
                    </div>
                    <div className="team-member">
                        <img src={team03} alt="Bill Stock" />
                        <div className="team-member-info">
                            <h3>Bill Stock</h3>
                            <p>Vice President, Creative and Marketing</p>
                        </div>
                        <div className="team-member-hover">
                            <p>Creative genius behind campaigns</p>
                        </div>
                    </div>
                    <div className="team-member">
                        <img src={team03} alt="Rusty Ranney" />
                        <div className="team-member-info">
                            <h3>Rusty Ranney</h3>
                            <p>Chief Executive Officer</p>
                        </div>
                        <div className="team-member-hover">
                            <p>Visionary leader driving innovation</p>
                        </div>
                    </div>
                    <div className="team-member">
                        <img src={team04} alt="Tom Marks" />
                        <div className="team-member-info">
                            <h3>Tom Marks</h3>
                            <p>Chief Financial Officer</p>
                        </div>
                        <div className="team-member-hover">
                            <p>Strategic financial mastermind</p>
                        </div>
                    </div>
                    <div className="team-member">
                        <img src={team05} alt="Bill Stock" />
                        <div className="team-member-info">
                            <h3>Bill Stock</h3>
                            <p>Vice President, Creative and Marketing</p>
                        </div>
                        <div className="team-member-hover">
                            <p>Creative genius behind campaigns</p>
                        </div>
                    </div>
                </div>
            </section> */}

            <section className="marketing-work">
                <h2 className="section-title">Our Marketing Work</h2>
                <div className="marketing-intro">
                    <p>
                        At Commissh, we take a comprehensive approach to event marketing, leveraging a mix of street
                        marketing, social media, and influencer collaborations to create buzz and drive engagement.
                    </p>
                </div>
                <div className="marketing-grid">
                    <div className="marketing-item">
                        <img src={streetMarketing} alt="Street Marketing" className="marketing-image" />
                        <h3>Street Marketing</h3>
                        <p>
                            Our roots are deeply embedded in the vibrant streets of Miami, where we use innovative
                            street marketing techniques to spread the word about our events. From rolling banners and
                            eye-catching posters to flyers and guerrilla marketing, we make sure our presence is felt
                            throughout the city.
                        </p>
                    </div>
                    <div className="marketing-item">
                        <img src={socialMediaMarketing} alt="Social Media Marketing" className="marketing-image" />
                        <h3>Social Media Marketing</h3>
                        <p>
                            In today's digital age, a strong online presence is essential. We harness the power of
                            social media to reach broader audiences, create meaningful interactions, and keep the
                            excitement alive leading up to our events. Our social media campaigns are designed to be
                            engaging and interactive.
                        </p>
                    </div>
                    <div className="marketing-item">
                        <img src={influencerMarketing} alt="Influencer Collaborations" className="marketing-image" />
                        <h3>Influencer Collaborations</h3>
                        <p>
                            We understand the impact that influencers have in shaping public opinion and driving
                            interest. That's why we work closely with a network of influencers who share our passion for
                            culture and community. These influencers help amplify our message and reach targeted
                            audiences.
                        </p>
                    </div>
                </div>
                <div className="marketing-conclusion">
                    <p>
                        By combining these marketing strategies, Commissh ensures that our events are not just noticed,
                        but eagerly anticipated by a diverse and engaged audience.
                    </p>
                </div>
            </section>
        </div>
    );
};

export default AboutUs;
