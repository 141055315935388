import { FaFacebookF, FaInstagram, FaTiktok } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { useEffect, useState } from "react";
import "./Footer.css";

const Footer = () => {
    const [userAgent, setUserAgent] = useState("");

    useEffect(() => {
        // Get user agent on component mount
        setUserAgent(navigator.userAgent.toLowerCase());
    }, []);

    const handleDownload = () => {
        // App store URLs (replace these with your actual app URLs)
        const IOS_APP_URL = "https://apps.apple.com/ca/app/commissh/id1553224421";
        const ANDROID_APP_URL = "https://play.google.com/store/apps/details?id=com.commisshcustomer";

        // Check if device is iOS
        const isIOS = /iphone|ipad|ipod|macintosh|mac os/.test(userAgent);
        // Check if device is Android
        const isAndroid = /android/.test(userAgent);

        if (isIOS) {
            window.open(IOS_APP_URL, "_blank");
        } else if (isAndroid) {
            window.open(ANDROID_APP_URL, "_blank");
        } else {
            // For desktop or unsupported devices, you might want to show both options
            // You could either open a modal here or redirect to a landing page
            alert(
                "Please visit us on your mobile device or choose your platform:\n\niOS: " +
                    IOS_APP_URL +
                    "\nAndroid: " +
                    ANDROID_APP_URL
            );
        }
    };

    return (
        <footer className="footer">
            <div className="footer-content">
                <div className="social-media-footer">
                    <h3>Connect with us</h3>
                    <div className="social-icons">
                        <a
                            href="https://www.facebook.com/profile.php?id=100063640896468"
                            className="icon-button facebook"
                            aria-label="Facebook"
                        >
                            <FaFacebookF />
                        </a>
                        <a
                            href="https://x.com/Commissh1"
                            className="icon-button x-twitter"
                            aria-label="X (formerly Twitter)"
                        >
                            <FaXTwitter />
                        </a>
                        <a
                            href="https://www.instagram.com/commissh/"
                            className="icon-button instagram"
                            aria-label="Instagram"
                        >
                            <FaInstagram />
                        </a>
                        <a href="https://www.tiktok.com/@commissh" className="icon-button tiktok" aria-label="TikTok">
                            <FaTiktok />
                        </a>
                    </div>
                </div>
                {/* <div className="cta-section">
                    <h3>Get the Commissh App</h3>
                    <button className="cta-button" onClick={handleDownload}>
                        Download Now
                    </button>
                </div> */}
                <div className="address-section">
                    <h3>Visit Us</h3>
                    <address>
                        251 Valencia Ave P.O. BOX# 140997
                        <br />
                        Coral Gables, FL 33114
                        <br />
                        305.767.6236
                        <br />
                        <a className="email-address" href="mailto:info@commissh.com">
                            info@commissh.com
                        </a>
                    </address>
                </div>
            </div>
            <div className="footer-bottom">
                <p>&copy; 2024 Commissh. All rights reserved.</p>
            </div>
        </footer>
    );
};

export default Footer;
