import { Routes, Route } from "react-router-dom";

import Navbar from "../Common/Navbar/index.js";
import LandingPage from "../Screens/LandingPage/index.js";
import Footer from "../Common/Footer/index.js";
import AboutUs from "../Screens/AboutUs/index.js";
import Contact from "../Screens/Contact/index.js";

const Web = () => {
    return (
        <div className="App">
            <Navbar />
            <div className="App">
                <Routes>
                    <Route path={"/"} exact={true} element={<LandingPage />} />
                    {/* <Route path="/ticket-giveaway" element={<TicketGiveaway />} /> */}
                    <Route path="/about-us" element={<AboutUs />} />
                    <Route path="/contact" element={<Contact />} />
                    {/* <Route path="/404" element={<NotFound />} /> */}
                    {/* <Route path="*" element={<Navigate to="/404" />} /> */}
                </Routes>
            </div>
            <Footer />
        </div>
    );
};

export default Web;
